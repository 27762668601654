import React from 'react'

import { Flex } from '../../Layout'
import { PSmall } from '../Typography'

export const ActivityLabel: React.FC<{ content: string; chip?: React.ReactNode }> = ({ content, chip }) => {
  return (
    <Flex gap={1} alignItems='center'>
      {!!chip && chip}
      <PSmall mb={0}>{content}</PSmall>
    </Flex>
  )
}
